import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/GlobalProvider";
import { GoldCoin_BAT } from "../../assets/data/Imagedata";
import {FaUserEdit,
  FaLifeRing,
  FaMoneyBillWave,
  FaBug,
} from "react-icons/fa";

function Dashboard() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate("/signin");
  };

  return (
    <section className="py-20 sm:py-24 px-5">
      <div className="flex justify-center ">
        {/* Center Column */}
        <div className="lg:w-1/3 w-full">
          {/* Only show Dashboard Actions if the user is not an admin */}
         
            <div className="flex-1 flex flex-col items-center py-10 bg-white rounded-2xl shadow-2xl">
              <section>
                <div className="max-w-full flex flex-col items-center justify-center rounded-2xl p-6 text-black">
                  {user ? (
                    <>
                      <div className="flex flex-col items-center ">
                        <img
                          src={GoldCoin_BAT}
                          alt="GoldCoin_BAT profile"
                          width={100}
                          height={100}
                          className="rounded-full"
                        />
                        <span className="font-bold text-lg">
                          Hello, {user.name}
                        </span>
                        {user.emailaddress}
                        {user.is_active === 1 || user.is_active === "1" ? (
                          <span className="text-white bg-blue-500 rounded-full text-[10px] px-2">
                            VERIFIED
                          </span>
                        ) : (
                          <span className="text-white bg-black rounded-full text-[10px] px-2">
                            NOT VERIFIED
                          </span>
                        )}
                        <div className="flex-row text-center">
                          <span className="text-[10px]">
                            {user.phonenumber}
                          </span>
                          <br />
                          <span className="text-[10px]">{user.address}</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <span className="text-black">Welcome Guest</span>
                  )}
                </div>
              </section>

              <div className="flex flex-col items-center justify-center w-full px-10">
                <div className="w-full grid grid-cols-1 gap-4 ">
                  <div className="border-t border-gray-300 " />

                  <div className="text-center ">
                    <p className="text-xl font-medium">Transactions</p>
                    <p className="text-sm">Check all your transactions</p>
                  </div>

                  <a href="/user/paymenthistory">
                    <button className="flex items-center justify-center text-black py-2">
                      <FaMoneyBillWave className="mr-3" size={23} />
                      <p className="text-[16px]">Payment History</p>
                    </button>
                    <span className="text-xs text-gray-600">
                      View your payment transactions and remaining balance.
                    </span>
                  </a>
                  <div className="border-t border-gray-300 "></div>
                  <div className="text-center">
                    <p className="text-xl font-medium">Personal</p>
                    <p className="text-sm">Modify your profile</p>
                  </div>

                  <Link to="/user/profileedit">
                    <button className="flex items-center justify-center text-black py-2">
                      <FaUserEdit className="mr-3" size={23} />
                      <p className="text-[16px]">Edit Profile</p>
                    </button>
                    <span className="text-xs text-gray-600">
                      Change your details. You cannot change your emailaddress,
                      please contact support.
                    </span>
                  </Link>

                  <div className="border-t border-gray-300 "></div>

                  <div className="text-center">
                    <p className="text-xl font-medium">Help Center</p>
                    <p className="text-sm">Need more help?</p>
                  </div>

                  <Link to="/contactus">
                    <button className="flex items-center justify-center text-black py-2">
                      <FaLifeRing className="mr-3" size={23} />
                      <p className="text-[16px]">Contact Us</p>
                    </button>
                    <span className="text-xs text-gray-600">
                      We are here to help you 24/7.
                    </span>
                  </Link>

                  <Link to="/contactus">
                    <button className="flex items-center justify-center text-black py-2">
                      <FaBug className="mr-3" size={23} />
                      <p className="text-[16px]">Report a Bug</p>
                    </button>
                    <span className="text-xs text-gray-600">
                      Having issues? Let us know so we can resolve them.
                    </span>
                  </Link>

                  <button
                    onClick={handleLogout}
                    className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition duration-300"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
