// SYSTEM_APIS
const SINGUP_API = "https://batchugold.com/apis/register/signup.php";
const SINGIN_API = "https://batchugold.com/apis/register/signin.php";
const GOLD_LIVE_PRICE= "https://batchugold.com/apis/LiveGoldApi.php"


// ADMIN
const CRUD_API = "https://batchugold.com/apis/CRUD.php";
const USER_FETCH_TRANSACTIONS_URL = "https://batchugold.com/apis/fetchTransactions.php";
const USER_CURRENCY_ANTS= "https://batchugold.com/apis/USER_CURRENCY_ANTS.php";


// MarketPlace-QuickBuy
const QUICK_BUY="https://batchugold.com/apis/PhonePe/DigitalGold/QuickBuy/PhonePe.php"

// MarketPlace-Daily
const DAILY_SUBSCRIPTION="https://batchugold.com/apis/PhonePe/DigitalGold/Daily/Dailysubscription.php"
const DAILY_SUBSCRIPTION_VERIFICATION="https://batchugold.com/apis/PhonePe/DigitalGold/Daily/verifyupi.php"
const DAILY_SUBSCRIPTION_SUBMITAUTH="https://batchugold.com/apis/PhonePe/DigitalGold/Daily/submit_auth_req.php"

// MarketPlace-Weekly 
const WEEKLY_SUBSCRIPTION="https://batchugold.com/apis/PhonePe/DigitalGold/Weekly/Weeklysubscription.php"
const WEEKLY_SUBSCRIPTION_VERIFICATION="https://batchugold.com/apis/PhonePe/DigitalGold/Weekly/verifyupi.php"
const WEEKLY_SUBSCRIPTION_SUBMITAUTH="https://batchugold.com/apis/PhonePe/DigitalGold/Weekly/submit_auth_req.php"

// MarketPlace-Monthly 
const MONTHLY_SUBSCRIPTION="https://batchugold.com/apis/PhonePe/DigitalGold/Monthly/Monthlysubscription.php"
const MONTHLY_SUBSCRIPTION_VERIFICATION="https://batchugold.com/apis/PhonePe/DigitalGold/Monthly/verifyupi.php"
const MONTHLY_SUBSCRIPTION_SUBMITAUTH="https://batchugold.com/apis/PhonePe/DigitalGold/Monthly/submit_auth_req.php"

//Store
const FETCH_ProductPost="https://batchugold.com/apis/Store/ProductPost.php"

export { SINGUP_API,SINGIN_API,
    CRUD_API, USER_FETCH_TRANSACTIONS_URL,
    GOLD_LIVE_PRICE ,

// Admin Tools
    USER_CURRENCY_ANTS,

    // MarketPlace-QuickBuy
    QUICK_BUY,

    // MarketPlace-Daily
    DAILY_SUBSCRIPTION,DAILY_SUBSCRIPTION_VERIFICATION,DAILY_SUBSCRIPTION_SUBMITAUTH,
    // MarketPlace-Weekly
    WEEKLY_SUBSCRIPTION,WEEKLY_SUBSCRIPTION_VERIFICATION,WEEKLY_SUBSCRIPTION_SUBMITAUTH,
    // MarketPlace-Monthly
    MONTHLY_SUBSCRIPTION,MONTHLY_SUBSCRIPTION_VERIFICATION,MONTHLY_SUBSCRIPTION_SUBMITAUTH,
    //Store
    FETCH_ProductPost,
};
